<!-- tabbar.vue组件文件 -->

<template>
  <div class="tabbar">
    <div
      :class="['tabbar-item', isShow == index ? 'is-show' : '']"
      v-for="(item, index) in list"
      :key="index"
      @click="tabbarChange(item.path,index)"
    >
      <img
        class="item-img"
        :src="item.icon_a"
        v-if="current == index"
      />
      <img class="item-img" :src="item.icon" v-else />
      <div
        class="item-name"
        :class="{ tabbarActive: current == index }"
        v-if="item.text"
      >
        {{ item.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  props: {
    current: Number,
    isShow: Number,
  },
  data() {
    return {
      paddingBottomHeight: 0, //苹果X以上手机底部适配高度
      list: [
        {
          text: "首页",
          icon: require("../assets/imgs/tab/home.png"), //未选中图标
          icon_a: require("../assets/imgs/tab/home_show.png"), //选中图片
          path: "/", //页面路径
        },
        {
          text: "客户",
          icon: require("../assets/imgs/tab/customer.png"),
          icon_a: require("../assets/imgs/tab/customer_show.png"),
          path: "/Customer",
        },
        {
          text: "应用",
          icon: require("../assets/imgs/tab/application.png"),
          icon_a: require("../assets/imgs/tab/application_show.png"),
          path: "/Application",
        },
        {
          text: "我的",
          icon: require("../assets/imgs/tab/mine.png"),
          icon_a:require("../assets/imgs/tab/mine_show.png"),
          path: "/Mine",
        },
      ],
    };
  },
  created() {},
  methods: {
    tabbarChange(path, index) {
     this.current == index ? "" : this.$router.push(path)
      
    },
  },
};
</script>

<style scoped>

.tabbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 125px;
  background-color: #fff;
  border-top: 1px solid #EDEDF7;
  
}

.tabbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.item-img {
  width: 45px;
  height: 45px;
}

.item-name {
  font-size: 22px;
  margin-top: 5px;
  color: #B2B4B6;
}

.tabbarActive {
    font-weight: 600;
      color: #101F48;
}

.is-show {
  display: none !important;
}
</style>
