<template>
  <div class="flex wrap application">
    <div
      class="icon flex-item_center column"
      v-for="(item, index) in iconArray"
      :key="index"
      @click="router(item, index)"
    >
      <img :src="item.icon" alt="" />
      <div class="icon-text">{{ item.name }}</div>
    </div>

    <tabbar :current="2" :isShow="6"></tabbar>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";
import Tabbar from "@/components/tabbar.vue";

export default {
  components: { Tabbar },
  data() {
    return {
      iconArray: [
        {
          icon: require("../assets/imgs/myStatistics.png"),
          name: "统计",
          path: "/Statistics",
        },
        {
          icon: require("../assets/imgs/myTask.png"),
          name: "我的任务",
          path: "/Task",
        },
        {
          icon: require("../assets/imgs/marketingActivities.png"),
          name: "营销活动",
          path: "/H5_luckdraw",
        },
        //   {
        //   icon: require("../assets/imgs/myBusinessCard.png"),
        //   name:"我的名片"
        // },
        // {
        //   icon: require("../assets/imgs/issuanceRecord.png"),
        //   name: "发券记录",
        //   path: "/MarketingActivities",
        // },
        {
          icon: require("../assets/imgs/myCode.png"),
          name: "我的活码",
          path: "/MyCode",
        },
        {
          icon: require("../assets/imgs/sendCircleofFriends.png"),
          name: "发朋友圈",
        },
        {
          icon: require("../assets/imgs/scriptSearch.png"),
          name: "话术搜索",
          path: "/Script",
        },
        //   {
        //   icon: require("../assets/imgs/task-task.png"),
        //   name:"我的任务"
        // },
      ],
    };
  },
  async mounted() {
    let that = this;
    let res = await request._getConfig({
      url: location.href.split("#")[0],
    });
    console.log("获取配置", res);
    if (_.toInteger(res.code) == 1) {
      console.log("进来额");
      let result = res.data;
      that.$wx.config({
        beta: true,
        debug: false, // 关闭调试模式
        appId: result.corpid,
        timestamp: result.timestamp,
        nonceStr: result.nonceStr,
        signature: result.signature,
        jsApiList: ["shareToExternalMoments"],
      });
      that.$wx.ready(function() {
        that.$wx.agentConfig({
          corpid: result.corpid,
          agentid: result.agentid,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature2,
          jsApiList: ["shareToExternalMoments"],
          success: function(res) {
            console.log("that.$wx.agentConfig 成功", res);
            // 回调
          },
          fail: function(res) {
            console.log("that.$wx.agentConfig 失败", res);
            if (res.errMsg.indexOf("function not exist") > -1) {
              alert("版本过低请升级");
            }
          },
        });
        that.$wx.error(function(res) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        });
      });
    }
  },
  methods: {
    router(item, index) {
      //
      if (index == 4) {
        // 发朋友圈
        this.$wx.invoke(
          "shareToExternalMoments",
          {
            text: {
              content: "请输入内容", // 文本内容
            },
            attachments: [
              {
                msgtype: "image", // 消息类型，必填
                image: {
                  imgUrl:
                    "https://wework.qpic.cn/wwpic/709343_kzYpT9DsTDaFX9I_1656580927/0", // 图片的imgUrl,跟图片mediaid填其中一个即可
                },
              },
            ],
          },
          function(res) {
            console.log("shareToExternalMoments 成功", res);
            if (res.err_msg == "shareToExternalMoments:ok") {
            }
          }
        );
      } else if(index == 2){
        this.$router.push(item.path);
      } else {
        this.$router.push(item.path);
      }
    },
  },
};
</script>

<style scoped>
.icon {
  width: 33.3%;
  height: 250px;
  background: #fff;
  border-bottom: 1px solid #ededf7;
  border-right: 1px solid #ededf7;
}

.application div:nth-child(3n) {
  border-right: none;
}

.icon-text {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  margin-top: 33px;
}
.icon img {
  width: 82px;
  height: 82px;
}
</style>
